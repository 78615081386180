import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'gatsby';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Requests from '../actions';
import IconEmail from '../assets/images/ic-email.inline.svg';
import iconSuccess from '../assets/images/success.png';
import iconBack from '../assets/images/back.png';
import Button from './Button';

const ForgotUsername = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ forgotUsername }) => {
    try {
      setLoading(true);
      const response = await Requests.forgotUsername(forgotUsername);
      setLoading(false);
      if (response.errorMessage) {
        toast.error(response.errorMessage, {
          hideProgressBar: true,
          className: 'toast',
        });
      } else {
        setIsSent(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const backBtn = (
    <Link to="/">
      <img src={iconBack} alt="back" />
      Back
    </Link>
  );

  return (
    <>
      <section className="forgotPassword">
        {!isSent ? (
          <div className="forgotPassword_wrapper-outer">
            <div className="forgotPassword_wrapper-inner">
              {backBtn}
              <h1>Forgot Username?</h1>
              <p>
                Please enter your email address and we will send your username by email immediately.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="forgotPassword_container__email">
                  <div
                    className={`forgotPassword_container_input ${
                      errors.forgotUsername ? 'forgotPassword_container_input--error' : ''
                    }`}
                  >
                    <IconEmail className={watch('forgotUsername') ? 'active-svg' : ''} />
                    <input
                      type="email"
                      className="forgotPassword-input__email"
                      placeholder="Enter your email please"
                      name="forgotUsername"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                <Button title="Send" loading={loading} />
              </form>
            </div>
          </div>
        ) : (
          <div className="forgotPassword_wrapper-inner forgotPassword_wrapper-inner--success">
            <img src={iconSuccess} alt="Don’t worry" />
            <h1>Don’t worry</h1>
            <p>We have e-mailed your username.</p>
            {backBtn}
          </div>
        )}
        <ToastContainer />
      </section>
    </>
  );
};

export default ForgotUsername;
