import React from 'react';

import Layout from '../components/Layout';
import ForgotUsername from '../components/ForgotUsername';

const ForgotUser = () => (
  <Layout>
    <ForgotUsername />
  </Layout>
);

export default ForgotUser;
